<template lang="pug">
    .main-wrapper.endpoints-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "endpoints" }'> Histórico de Integrações</router-link> /
                            <b>Detalhes</b>
    
            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waiting' mode="indeterminate")
                div(v-else)
                    Panel(:header="'Detalhes do Histórico de Integrações'")
                        .p-grid.detalhes
                            .p-col-6
                                label.form-label Mensagem
                                TextArea(readonly v-model='model.ds_mensagem'  :autoResize="true" rows="5" cols='60')
                            .p-col-6
                                label.form-label Descrição:
                                TextArea(readonly v-model='model.ds_descricao'  :autoResize="true" rows="5" cols='60')
                            .p-col-3
                                label.form-label Endpoint utilizado:
                                InputText(type='text' v-model='model.ds_endpoint' readonly)
                            .p-col-3
                                label.form-label Tipo de Histórico:
                                InputText(type='text' :value="v-model ? 'Envio' : 'Recebimento'" readonly)
                            .p-col-3
                                label.form-label Serviço:
                                InputText(type='text' v-model='model.servico' readonly)
                            .p-col-3
                                label.form-label Data de criação:
                                InputText(type='text' :value="formatDate(model.dt_criado)" readonly)
                            .p-col-12
                                label.form-label Corpo da requisição:
                                pre {{ pretty(model.ds_body) }}
    </template>

    <script>
        import ProgressBar from 'primevue/progressbar'
        import Panel from 'primevue/panel'
        import InputText from 'primevue/inputtext'
        import Button from 'primevue/button'
        import Tooltip from 'primevue/tooltip'
        import TextArea from 'primevue/textarea';
    
        import { HistoricoIntegracao } from './../../middleware'
        import {formatDateToBR} from '@/utils.js'
    
        export default {
            components: {
                ProgressBar, Panel, InputText, Button, Tooltip, TextArea, formatDateToBR
            },
            directives: { tooltip: Tooltip },
            created () {
                this.waiting = true
    
                let id = 0
                if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
                if (id != 0) {
                     HistoricoIntegracao.find(id).then(response => {
                        if (response.status == 200) {
                            let keys = Object.keys(this.model)
                            
                            keys.forEach(key => this.model[key] = response.data[key])
                            
                            this.model.id = id;
                        }
                        this.waiting = false
                    })
                } else this.waiting = false  
    
            },
            data () {
                return {
                    model: {
                        dt_criado: '',
                        ds_descricao: '',
                        ds_mensagem: '',
                        ds_level: '',
                        ds_body: '',
                        ds_endpoint: '',
                        ie_tipo_historico: '',
                        servico: '',
                    },
                    waiting: true,
                }
            },
            methods: {
                formatDate(dt){
                    return formatDateToBR(dt)
                },
                pretty: function(value) {
                    return JSON.stringify(JSON.parse(value), null, 2);
                }
            }
          }
    </script>